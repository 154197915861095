@import 'react-simple-keyboard/build/css/index.css';

a {
  color: inherit;
  text-decoration: none;
}

.swal2-container {
  z-index: 10000;
}

.simplebar-content-wrapper {
  outline: none;
}

.swal2-html-container {
  line-height: 1.5 !important;
}